<template>
    <div>
        <Header></Header>
        <div class="outer_box content_box">
            <p>StarEastNet Portal Limited</p>
            <p>Suite 2214, 22/F, Miramar Tower, 132 Nathan Road, Tsim Sha Tsui Kowloon Hong Kong</p>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import Header from "@/components/header.vue"
import Footer from "@/components/footer.vue"
export default { 
    components:{ Header, Footer },
}
</script>
<style scoped lang="less">
.content_box{
    padding: 20px 4px;
    p:nth-child(1){
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;
    }
    p:nth-child(2){
        color: #5f5f5f;
    }
}
</style>